/* eslint-disable react-hooks/exhaustive-deps */
/*
 * @Description: 关注并搜索商家
 * @Author: Evan huang
 * @Date: 2023-12-22 14:52:52
 * @LastEditors: Evan huang
 * @LastEditTime: 2024-11-25 22:18:40
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/screens/MerchantListSearch.tsx
 */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Dimensions,
  Image,
  FlatList,
  StyleSheet,
  TextInput,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import Modal from '@ant-design/react-native/lib/modal';

import { encode as base64_encode } from 'base-64';
import { colors, images } from '../utils';
import { MAllLoadedBox, MEmptyBox, MMerchantInfoItem } from '../components';

const MerchantListSearchScreen = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'clientClient/getCurrentPage',
        payload: { name: 'MerchantListSearchScreen' },
      });
    }, [dispatch]),
  );
  const { width } = Dimensions.get('window');

  const [searchKeyword, setSearchKeyword] = useState('');

  //公众号一级跳转 URL
  const jumpH5Url = useSelector(
    (state: any) => state.parameter.h5url.itemValue,
  );

  const { allMerchantList, merchantList } = useSelector(
    (state: any) => state.clientMerchant,
  );

  /** 搜索记录 */
  const fetchData = useCallback(
    (page: number = 1) => {
      if (searchKeyword) {
        dispatch({
          type: 'clientMerchant/getAllMerchantList',
          payload: { page, keyword: searchKeyword },
        });
      } else {
        dispatch({
          type: 'clientMerchant/getMerchantList',
          payload: { page, keyword: searchKeyword },
        });
      }
    },
    [dispatch, searchKeyword],
  );

  useEffect(() => {
    fetchData();
  }, []);

  const onFollow = useCallback(
    async (id, callback) => {
      dispatch({
        type: 'clientMerchant/followMerchant',
        payload: { merchantId: id },
        callback: () => {
          fetchData();
          callback && callback();
        },
      });
    },
    [dispatch, fetchData],
  );

  const onUnfollow = useCallback(
    async (id) => {
      await dispatch({
        type: 'clientMerchant/unfollowMerchant',
        payload: {
          merchantId: id,
        },
        callback: () => fetchData(),
      });
    },
    [dispatch, fetchData],
  );

  const switchCurrentMerchant = useCallback(
    (id) => {
      Modal.alert('提示', '是否切换到该商家？', [
        {
          text: '取消',
          style: 'cancel',
        },
        {
          text: '确认',
          onPress: async () => {
            const callback = () => {
              // dispatch({
              //   type: 'clientMerchant/getCurrentMerchant',
              //   payload: { merchantId: id },
              // });
              // dispatch({
              //   type: 'clientMerchant/getArticleList',
              //   payload: { merchantId: id, mode: '0' },
              // });
              let homeUri = `/api/client/share/article?merchantId=${base64_encode(
                id,
              )}&type=merchant_import&download=1`;
              const { location } = window;
              location.href = jumpH5Url + homeUri;
            };
            await onFollow(id, callback);
            // navigation.navigate('main');
          },
        },
      ]);
    },
    [dispatch, navigation, onFollow],
  );

  const startPush = useCallback(
    async (id) => {
      await dispatch({
        type: 'clientMerchant/sendMerchant',
        payload: {
          merchantId: id,
        },
      });
      fetchData(1);
    },
    [dispatch, fetchData],
  );

  /** 根据搜索条件获得返回不同的结果字段 */
  const getSearchResults = useCallback(() => {
    if (searchKeyword.trim().length > 0) {
      return allMerchantList;
    } else {
      return merchantList;
    }
  }, [allMerchantList, merchantList, searchKeyword]);

  const headerView = (
    <View style={[styles.searchBar, { width: width - 30 }]}>
      <View style={styles.searchInputSection}>
        <Image source={images.SEARCH_ICON} style={styles.searchInputIcon} />
        <TextInput
          returnKeyType="search"
          placeholder="输入商家全称可以搜索并关注TA"
          onChangeText={(val) => {
            val = val.trim();
            setSearchKeyword(val);
            if (val.length === 0) {
              dispatch({
                type: 'clientMerchant/getMerchantList',
                payload: { page: 1 },
              });
            } else {
              //如果存在上次数据则直接清除
              dispatch({
                type: 'clientMerchant/getMerchantList',
                payload: { page: 1, keyword: val },
              });
              // }
            }
          }}
          onSubmitEditing={() => {
            dispatch({
              type: 'clientMerchant/getAllMerchantList',
              payload: {
                page: 1,
                keyword: searchKeyword,
              },
            });
          }}
          style={[styles.searchInput, { width }]}
        />
      </View>
    </View>
  );

  const renderItem = ({ item }) => (
    <MMerchantInfoItem
      profileIcon={item.avatar ? { uri: item.avatar } : images.LOGO}
      data={item}
      name={item.name}
      desc={item.intro}
      sendFlag={item.sendFlag}
      following={item.following}
      noticeNumber={item.articleCount}
      onClick={() => switchCurrentMerchant(item.id)}
      onFollow={() => onFollow(item.id)}
      onUnfollow={() => onUnfollow(item.id)}
      startPush={() => startPush(item.id)}
    />
  );

  return (
    <View style={styles.mainContainer}>
      {headerView}
      <FlatList
        data={getSearchResults().records}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        refreshing={false}
        onRefresh={() => fetchData(1)}
        onEndReached={() => {
          if (!getSearchResults().isAllLoaded) {
            fetchData(getSearchResults().page);
          }
        }}
        ListEmptyComponent={<MEmptyBox title="暂无数据" />}
        ListFooterComponent={
          getSearchResults().records.length &&
          getSearchResults().isAllLoaded ? (
            <MAllLoadedBox />
          ) : null
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  mainBody: {
    flex: 1,
    backgroundColor: colors.DEFAULT_BG_COLOR,
  },
  searchBar: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 44,
    marginLeft: 16,
    paddingBottom: 5,
    borderBottomColor: colors.MERCURY,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  searchInputSection: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.DEFAULT_BG_COLOR,
    borderRadius: 4,
    height: 35,
    paddingLeft: 12,
  },
  searchInputIcon: { width: 14, height: 14, marginRight: 6, opacity: 0.7 },
  scanInputIcon: { width: 22, height: 22, marginRight: 6, opacity: 0.7 },
  searchInput: {
    height: 35,
    color: colors.DUSTY_GRAY,
    fontSize: 14,
    minWidth: 250,
    padding: 0,
  },
  searchCancelSection: { marginLeft: 16 },
  searchCancelText: { flex: 0, color: colors.WHITE, fontSize: 16 },
  contentBody: { backgroundColor: colors.WHITE },
  searchHistorySection: { marginTop: 12, paddingLeft: 16, paddingRight: 16 },
  searchHistoryHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchHistoryDelIcon: { width: 16, height: 16 },
  searchHistoryTitle: { color: colors.GRAY20, fontSize: 18 },
  searchHistoryList: {
    marginTop: 14,
    maxHeight: 80,
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'hidden',
    marginLeft: -12,
  },
  searchHistoryItem: {
    marginLeft: 12,
    marginBottom: 12,
    backgroundColor: colors.WILD_SAND,
    paddingLeft: 12,
    paddingRight: 12,
    paddingVertical: 6,
  },
  searchHistoryItemText: { color: colors.GRAY20, fontSize: 14 },
  flatList: {
    paddingTop: 10,
  },
});
export default MerchantListSearchScreen;
