/* eslint-disable react-native/no-inline-styles */
/* eslint-disable no-useless-escape */
/*
 * @Author: Evan huang
 * @Date: 2021-07-19 14:34:06
 * @LastEditTime: 2024-12-09 21:08:42
 * @LastEditors: Evan huang
 * @Description: In User Settings Edit
 * @FilePath: /msll_h5/src/navigators/index.tsx
 */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { NavigationContainer } from '@react-navigation/native';

import { navigationRef } from './RootNavigation';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Dimensions, Text, TouchableOpacity } from 'react-native';
import { decode as base64_decode } from 'base-64';
import Toast from '@ant-design/react-native/lib/toast';
import MainNavigator from './Main';
import { colors } from '../utils';

const TOKEN_KEY = 'token';
const MERCHANT_ID_KEY = 'currentMerchantId';
const MERGECODE_ID_KEY = 'mergeCodeId';

/**
 * 随机生成URI链接->字母+数组
 * @param length 长度
 * @returns 示例输出：aB3dE9Gh
 */
const randomURI = (length = 8) => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    result += chars[randomIndex];
  }
  return result;
};

// Web 必须配置此path进行映射
const config = {
  screens: {
    Home: {
      path: 'home/' + randomURI(),
    },
    Link: {
      path: 'link',
    },
    OrderDetail: {
      path: 'order/' + randomURI(),
    },
    //匹配支付后的随机数链接
    OrderDetailBack: {
      path: 'order/:randomURI',
    },
    //匹配支付后的随机数链接
    OrderDetailQRCode: {
      path: 'order',
    },
    BalanceRecharge: {
      path: 'recharge',
    },
    BalancePayment: {
      path: 'payment',
    },
    PaymentSuccess: {
      path: 'paysuccess',
    },
    Wallet: {
      path: 'wallet',
    },
    TopUp: {
      path: 'topup',
    },
    MerchantApply: {
      path: 'apply',
    },
    ApplySubmitSuccess: {
      path: 'applySubmitSuccess',
    },
    Suggest: {
      path: 'suggest',
    },
    OrderScreen: {
      path: 'orderScreen/' + randomURI(),
    },
  },
};

const linking = {
  prefixes: [],
  config,
};

// 删除url中指定参数，name为参数名
export const delUrlParams = (name: string | undefined) => {
  var loca = window.location;
  var baseUrl = loca.origin + loca.pathname + '?';
  var query = loca.search.substr(1);
  if (name && query.indexOf(name) > -1) {
    var obj = {};
    var arr: any = query.split('&');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('=');
      obj[arr[i][0]] = arr[i][1];
    }
    delete obj[name];
    var url =
      baseUrl +
      JSON.stringify(obj)
        .replace(/[\"\{\}]/g, '')
        .replace(/\:/g, '=')
        .replace(/\,/g, '&');
    return url;
  }
  return baseUrl + loca.search;
};

function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}

const Navigator = () => {
  const dispatch = useDispatch();
  const [routeName, setRouteName] = useState('main');

  useEffect(() => {
    //获取 jump_h5_url 参数
    //公众号跳转一级URl,公众号跳转流程: 一级jump_h5_url参数QRCode->商户配置的SubDomain
    dispatch({
      type: 'parameter/getByCode',
      payload: { code: 'jump_h5_url' },
    });
    dispatch({
      type: 'parameter/getByCode',
      payload: {
        code: 'platform_support_qrcode',
      },
    });
    dispatch({
      type: 'parameter/getByCode',
      payload: {
        code: 'platform_support_wechat',
      },
    });
    //不中退还提示，不退款提示
    dispatch({
      type: 'parameter/getByCode',
      payload: {
        code: 'refund_tips',
      },
    });
    dispatch({
      type: 'parameter/getByCode',
      payload: {
        code: 'no_refund_tips',
      },
    });

    let token = getQueryString(TOKEN_KEY);
    let merchantId = getQueryString('merchantId');
    merchantId = merchantId ? base64_decode(merchantId) : null;

    if (token) {
      // 获取当前访问用户信息 by Token
      AsyncStorage.setItem(TOKEN_KEY, token);
    }
    if (merchantId) {
      // 获取当前访问的推荐商家ID
      AsyncStorage.setItem(MERCHANT_ID_KEY, merchantId);
    }

    let multiId = getQueryString('multiId');
    multiId = multiId ? base64_decode(multiId) : null;
    if (multiId) {
      // 获取当前访问的合并码
      AsyncStorage.setItem(MERGECODE_ID_KEY, multiId);
    }
  }, [dispatch]);

  const backButton = () => {
    //如果为首页则不显示返回按钮
    if (
      routeName === 'main' ||
      routeName === 'merchant' ||
      routeName === 'my'
    ) {
      return null;
    }
    return (
      <TouchableOpacity
        style={{
          position: 'absolute',
          bottom: 100,
          right: 10,
          borderColor: colors.SILVER,
          borderWidth: 1,
          paddingVertical: 15,
          paddingHorizontal: 10,
          borderRadius: 30,
          backgroundColor: 'transparent',
        }}
        onPress={() => navigationRef.current.goBack()}>
        <Text style={{ color: colors.GUARDSMAN_RED, fontWeight: 'bold' }}>
          返回
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaProvider>
      <NavigationContainer
        linking={linking}
        ref={navigationRef}
        onStateChange={() => {
          setRouteName(navigationRef.current.getCurrentRoute().name);
        }}
        onReady={async () => {
          const route = navigationRef.current.getCurrentRoute();
          const token = getQueryString(TOKEN_KEY);
          const merchantId = await AsyncStorage.getItem('currentMerchantId');

          if (token) {
            // 删除url中的token值
            const url = delUrlParams(TOKEN_KEY);
            // 跳转至新rul
            window.location.href = url;
          }

          dispatch({
            type: 'client/getCurrentClient',
            payload: { merchantId },
          });

          // 检测访问域名是否为授权域名, 商家申请页不做判定
          if (route.name !== 'Apply') {
            if (merchantId) {
              dispatch({
                type: 'clientMerchant/getCurrentMerchant',
                payload: { merchantId },
                callback: (merchantInfo) => {
                  // 初始化微信SDK，
                  dispatch({
                    type: 'wechat/initWechat',
                    payload: { wechatpayId: merchantInfo.wechatpayId },
                  });
                  //获取支付配置
                  dispatch({
                    type: 'client/getAdapayVosByMerchantId',
                    payload: { merchantId: merchantInfo.id },
                  });
                  //属于不同Host则禁止访问
                  if (
                    merchantInfo.subDomain.indexOf(window.location.host) === -1
                  ) {
                    navigationRef.current?.reset({
                      index: 0,
                      routes: [{ name: 'Link' }],
                    });
                  }
                },
              });
            } else {
              Toast.info('请先关注一个商家', 1);
            }
          }

          //查询余额
          dispatch({
            type: 'client/userWalletBalance',
            payload: {},
          });
          const { height } = Dimensions.get('window');
          dispatch({
            type: 'screen/setScreenHeight',
            payload: height,
          });
        }}
        documentTitle={{
          formatter: (options) => options?.title + ' - 码上来料',
        }}>
        <MainNavigator />
        {backButton()}
      </NavigationContainer>
    </SafeAreaProvider>
  );
};

export default connect((state: any) => ({
  loading: state.loading,
}))(Navigator);
